import React from "react"
import { Link } from "gatsby"
import pixelFrame from "../assets/pixel-frame-solid.png"
import { GatsbyImage } from "gatsby-plugin-image"

const ProjectItem = ({ data }) => {
  return (
    <div className="project-container">
      <div className="text">
        <h2>{data.title}</h2>
        <div className="details">
          <h5>{data.tag}</h5>
          <h5>{data.completed}</h5>
        </div>

        <p>{data.exerpt}</p>
        <Link to={data.projectPath}>
          <button>More info</button>
        </Link>
      </div>
      <div
        className="image"
        style={{
          maskImage: `url(${pixelFrame})`,
          WebkitMaskImage: `url(${pixelFrame})`,
          WebkitMaskRepeat: "no-repeat",
          WebkitMaskPosition: "center",
        }}
      >
        <GatsbyImage
          image={data.cover.localFile.childImageSharp.gatsbyImageData}
          alt=""
        />
      </div>
    </div>
  )
}

export default ProjectItem
