import React from "react"
import ProjectItem from "../components/ProjectItem"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const Projects = ({ data }) => {
  const strapiSeo = data.strapiProjectPage.seo
  return (
    <>
      <Seo
        title={strapiSeo.metaTitle}
        description={strapiSeo.metaDescription}
        image={strapiSeo.sharedImage.media.url}
        imageAlt={strapiSeo.sharedImage.alt}
      />
      <div className="page-content">
        <header className="upper-content">
          <div className="header-content">
            <h1>Projects</h1>
            <p>{data.strapiProjectPage.description}</p>
          </div>
        </header>
        <main className="lower-content">
          {data.allStrapiProject.nodes.map((item, index) => {
            return <ProjectItem data={item} key={index} />
          })}
        </main>
      </div>
    </>
  )
}

export default Projects

export const query = graphql`
  query projectListQuery {
    strapiProjectPage {
      description
      seo {
        metaTitle
        metaDescription
        preventIndexing
        sharedImage {
          alt
          media {
            url
          }
        }
      }
    }
    allStrapiProject(sort: { fields: [completed], order: DESC }) {
      nodes {
        title
        exerpt
        projectPath: gatsbyPath(filePath: "/projects/{strapiProject.slug}")
        completed(formatString: "MMMM Do, YYYY")
        tag
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
